import { Formulier, Primitives, Values } from "@formulier/react";
import React, { ReactNode, useRef } from "react";

import { isMessageDescriptor, MessageDescriptor, useIntl } from "~/intl";

import { Form, FormSubmitContext } from "./form";
import { SheetCloseButton, SheetContent, SheetLayout, SheetSubmitButton, SheetTitle } from "./sheet";

export function SheetForm<V extends Values, P extends Primitives>({
  title,
  form,
  onSubmit,
  children,
  submitting,
}: {
  title: string | MessageDescriptor;
  form: Formulier<V, P>;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>, context: FormSubmitContext) => void;
  children: ReactNode;
  submitting: boolean | undefined;
}) {
  const { formatMessage } = useIntl();
  const formRef = useRef<HTMLDivElement>(null);

  const handleSubmitClick = () => {
    const element = formRef.current;
    if (!element) return;

    const form = element.nodeName === "FORM" ? element : element.querySelector("form");
    if (!form) return;

    let submitButton = form.querySelector<HTMLButtonElement>('button[type="submit"]');

    if (submitButton) {
      submitButton.click();
    } else {
      submitButton = document.createElement("button");
      submitButton.type = "submit";
      form.appendChild(submitButton);
      submitButton.click();
      submitButton.remove();
    }
  };

  return (
    <SheetLayout>
      <SheetTitle>{isMessageDescriptor(title) ? formatMessage(title) : title}</SheetTitle>

      <SheetContent ref={formRef} className="px-0">
        <Form method="POST" form={form} onSubmit={onSubmit}>
          <div className="px-6">{children}</div>
        </Form>
      </SheetContent>

      <SheetCloseButton />
      <SheetSubmitButton onClick={handleSubmitClick} disabled={submitting} />
    </SheetLayout>
  );
}
